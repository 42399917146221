import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const { Button } = BaseRowTheme;

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: ``,
    headline: `text-headline-3xs sm:text-headline-3xs uppercase after:h-2.5 after:bg-primary-700`,
  },
});

export default Object.assign(Row, { Button });
