import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: ImageTheme,
  base: `aspect-[40/21] md:!mx-auto md:w-full`,
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    caption: `text-headline-3xs order-3 text-gray-600`,
    description: `text-body-sm`,
    group: `gap-2`,
    headline: `text-headline-xs`,
  },
  variants: {
    variant: {
      horizontal: {
        base: `mx-auto grid-cols-[1fr_2fr] px-3 sm:px-0 lg:max-w-cols-8`,
        headline: `sm:text-headline-sm sm:grid-cols-7:text-headline-sm sm:grid-cols-8:text-headline-md`,
        description: `sm:text-body-md sm:grid-cols-7:mt-0`,
      },
      vertical: {
        base: `mx-auto gap-2 lg:max-w-cols-8`,
        group: `grid-cols-8:px-3 sm:grid-cols-7:mt-1 sm:grid-cols-7:gap-3 sm:grid-cols-8:px-0`,
        headline: [
          'grid-cols-7:text-headline-lg',

          'sm:text-headline-md',
          'sm:grid-cols-4:text-headline-md',
          'sm:grid-cols-7:text-headline-2xl',

          'md:grid-cols-4:text-headline-md',
          'md:grid-cols-7:text-headline-2xl',
        ],
        description: `mt-0 sm:grid-cols-4:mt-0 sm:grid-cols-7:mt-0`,
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
